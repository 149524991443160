import {Fragment, useEffect, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {SelectorIcon} from '@heroicons/react/solid';
import {useTranslation} from 'react-i18next';
import {checkDmr, voucher} from '../confirm/store/informationSlice';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {showMessage} from '../features/store/messageSlice';
import TermsModal from '../modals/TermsModal';
import {countryFlags} from "../features/CountryFlags";
import {validateOnKeyDownInput, validateOnPasteInput} from '../../utils/validations';

export default function Voucher() {
    const [selected, setSelected] = useState(countryFlags[0]);
    const {t} = useTranslation();
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptGdpr] = useState(true);
    const [canContinue, setCanContinue] = useState(false);
    const [licensePlate, setLicensePlate] = useState('');
    const [code, setCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [keyboardIsShown, setKeyboardIsShown] = useState(false);
    const initialScreenSize = window.innerHeight;
    window.addEventListener('resize', handleResize);
    let isPhone = initialScreenSize <= 766;

    useEffect(() => {
        let timeout;
        if (licensePlate || phoneNumber || acceptTerms || code) {
            timeout = setTimeout(() => {
                setLicensePlate('');
                setPhoneNumber('');
                setCode('');
                setAcceptTerms(false);
            }, [30 * 1000]);
        }

        if (!licensePlate && !phoneNumber && !acceptTerms && !code) clearTimeout(timeout);

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [acceptTerms, licensePlate, phoneNumber, code]);

    function handleChange(event) {
        const input = event.target;
        setLicensePlate(input.value.toUpperCase());
    }

    function handleResize() {
        if (initialScreenSize > window.innerHeight && !keyboardIsShown) {
            setKeyboardIsShown(true);
        } else {

            setKeyboardIsShown(false);
        }
    }

    useEffect(() => {
        if (acceptTerms && acceptGdpr && licensePlate !== '') {
            setCanContinue(true);
        } else {
            setCanContinue(false);
        }
    }, [acceptTerms, acceptGdpr, licensePlate]);

    function Continue() {
        dispatch(checkDmr(licensePlate)).then(res => {
            dispatch(voucher({licensePlate: licensePlate, email: phoneNumber !== '' ? selected.countryCode + phoneNumber : '', voucherCode: code})).then(res => {
                if (res.payload) {
                    navigate(`/confirm`);
                } else {
                    dispatch(showMessage({variant: 'error', message: t('Ukendt kode, prøv venligst med en anden.')}));
                }
            });
        });
    }

    return (
        <>

            {!keyboardIsShown && !isPhone ? (
                <>
                    <div>

                        <div className="w-full sm:mb-0 l:mb-20">

                            <div className="flex justify-center mt-10">
                                <p className="text-3xl lg:text-5xl sm:text-s">{t('Opret P-tilladelse')}</p>
                            </div>
                            <div className="flex mb-12 mt-8 justify-center items-center text-md px-5">
                                <p>{t('Indtast dine informationer herunder for at oprette din P-tilladelse')}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex justify-center items-center">
                                <form autoComplete="off" className="w-full justify-center items-center">
                                    <div className="flex justify-center items-center mb-2">
                                        <div className="lg:w-2/5 md:w-3/5 w-4/5">
                                            <input value={licensePlate} onChange={(event) => handleChange(event)}
                                                   className="bg-white text-xs md:text-xl font-bold p-4 appearance-none border-none shadow-md rounded w-full focus:outline-blue text-gray-700"
                                                   id="inline-full-name" type="text"
                                                   placeholder={t('Indtast registreringsnummer')}
                                                   onKeyDown={validateOnKeyDownInput}
                                                   onPaste={validateOnPasteInput}
                                                   maxLength={9}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex mb-6 justify-center items-center text-sm">
                                        <p>{t('Indtast kun bogstaver og tal')}</p>
                                    </div>
                                    <div className="flex justify-center items-center mb-2 md:mt-20 mt-0">
                                        <div className="lg:w-2/5 md:w-3/5 w-3/5 w-4/5">
                                            <input value={code} onChange={(event) => setCode(event.target.value)}
                                                   className="bg-white text-xs md:text-xl font-bold p-4 appearance-none border-none shadow-md rounded w-full focus:outline-blue text-gray-700"
                                                   id="inline-full-name" type="text"
                                                   placeholder={t('Indtast den udleverede/købte kode')}/>
                                        </div>

                                    </div>
                                    <div className="flex mb-6 justify-center items-center text-sm">
                                        <p>{t('Husk store og små bogstaver')}</p>
                                    </div>
                                    <div className="flex justify-center items-center mb-2 md:mt-20 mt-0">

                                        <Listbox value={selected} onChange={setSelected}>
                                            <div
                                                className="flex lg:w-2/5 md:w-3/5 w-3/5 w-4/5 items-center bg-white appearance-none border-none shadow-md rounded">

                                                <Listbox value={selected} onChange={setSelected}>
                                                    <div className="relative mt-1">
                                                        <Listbox.Button
                                                            className="flex items-center relative w-full pl-3 pr-10 text-left cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                                            <span className="pr-2">
                                                <img alt="lang" width={40} height={20} src={selected.icon}/>
                                            </span>
                                                            <span
                                                                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                                                </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options
                                                                className="absolute w-full z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {countryFlags.map((person, personIdx) => (
                                                                    <Listbox.Option
                                                                        key={personIdx}
                                                                        className={({active}) =>
                                                                            `${active ? 'bg-red-200' : ''} cursor-pointer select-none py-2 flex justify-center `
                                                                        }
                                                                        value={person}
                                                                    >
                                                                        {({}) => (
                                                                            <>
                                                        <span
                                                            className=""
                                                        >
                                                            <img alt="sprog" width={40} height={40} src={person.icon}/>
                                                        </span>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ))}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </Listbox>
                                                <div
                                                    className="text-xs md:text-xl appearance-none font-bold py-4 bg-transparent border-0 rounded text-gray-700 px-1 focus:outline-blue active:outline-none">
                                       <span className="ml-2 md:text-xl opacity-80">
                                          00{selected.countryCode}
                                       </span>
                                                </div>
                                                <input
                                                    value={phoneNumber}
                                                    onChange={event => setPhoneNumber(event.target.value)}
                                                    className="text-xs md:text-xl appearance-none font-bold py-4 bg-transparent border-0 md:w-full rounded text-gray-700 px-2 focus:outline-blue active:outline-none"
                                                    type="tel"
                                                    placeholder={t('Indtast mobilnummer (valgfri)')}
                                                    aria-label="phonenumber"
                                                    onKeyDown={validateOnKeyDownInput}
                                                    onPaste={validateOnPasteInput}
                                                />
                                            </div>
                                        </Listbox>


                                    </div>

                                    <div className="flex mb-0 md:mb-12 justify-center items-center text-sm">
                                        <p>{t('Indtast mobilnummer og få en kvittering')}</p>
                                    </div>
                                    <div className="flex mb-6 mb-0 md:mt-10 mt-0 justify-center">
                                        <label className="inline-flex items-center">
                                            <input type="checkbox" checked={acceptTerms}
                                                   onClick={(ev) => setAcceptTerms(ev.target.checked)}
                                                   style={{color: "#07395c", outlineColor: "#07395c"}}
                                                   className="form-checkbox rounded p-3"/>
                                            <span className="ml-2 text-lg"> {t('Jeg accepterer')} <TermsModal
                                                text={t('vilkår og betingelser')} type={0}
                                                language={selected}/></span>
                                        </label>
                                    </div>
                                    <div className="flex mb-12 mt-6 justify-center items-center text-sm px-5">
                                        <p className="w-4/5 md:w-2/5 break-words">
                                            {t('GDPR')} <TermsModal
                                            text={t('GDPRLink')} type={1}
                                            language={selected}/>
                                        </p>
                                    </div>
                                    <div className="flex mb-6 justify-center items-center">
                                        <button disabled={!canContinue} style={{backgroundColor: "#07395c"}}
                                                className="l:w-2/5 md:w-2/5 w-2/5 bg-lightBlue-500 disabled:opacity-50 shadow-md text-white font-bold uppercase px-8 py-3 rounded hover:shadow-lg outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button" onClick={() => Continue()}>
                                            {t('Fortsæt')}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </>

            ) : (
                <>
                    <div>

                        <div className="w-full mb-6">

                            <div className="flex justify-center mt-10">
                                <p className="text-3xl lg:text-5xl">{t('Opret P-tilladelse')}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex justify-center items-center">
                                <form autoComplete="off" className="w-full justify-center items-center">
                                    <div className="flex justify-center items-center mb-2">
                                        <div className="lg:w-3/5 md:w-4/5 w-4/5">
                                            <input value={licensePlate} onChange={(event) => handleChange(event)}
                                                   className="text-xs md:text-xl bg-white font-bold p-4 appearance-none border-none shadow-md rounded w-full focus:outline-blue text-gray-700"
                                                   id="inline-full-name" type="text"
                                                   placeholder={t('Indtast registreringsnummer')}
                                                   onKeyDown={validateOnKeyDownInput}
                                                   onPaste={validateOnPasteInput}
                                                   maxLength={9}/>
                                        </div>
                                    </div>
                                    <div className="flex mb-6 justify-center items-center text-sm">
                                        <p>{t('Indtast kun bogstaver og tal')}</p>
                                    </div>
                                    <div className="flex justify-center items-center mb-2 md:mt-20 mt-0">
                                        <div className="lg:w-3/5 md:w-4/5 w-4/5">
                                            <input onChange={(event) => setCode(event.target.value)}
                                                   className="text-xs md:text-xl bg-white font-bold appearance-none border-none p-4 shadow-md rounded w-full md:w-full focus:outline-blue text-gray-700"
                                                   id="inline-full-name" type="text"
                                                   placeholder={t('Indtast den udleverede/købte kode')}/>
                                        </div>

                                    </div>
                                    <div className="flex mb-6 justify-center items-center text-sm">
                                        <p>{t('Husk store og små bogstaver')}</p>
                                    </div>
                                    <div className="flex justify-center items-center mb-2">

                                        <Listbox value={selected} onChange={setSelected}>
                                            <div
                                                className="flex lg:w-3/5 md:w-4/5 w-4/5 items-center bg-white appearance-none border-none shadow-md rounded">

                                                <Listbox value={selected} onChange={setSelected}>
                                                    <div className="relative mt-1">
                                                        <Listbox.Button
                                                            className="flex items-center relative w-full pl-3 pr-10 text-left cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                                            <span className="pr-2">
                                                <img alt="lang" width={40} height={20} src={selected.icon}/>
                                            </span>
                                                            <span
                                                                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                                                </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options
                                                                className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {countryFlags.map((person, personIdx) => (
                                                                    <Listbox.Option
                                                                        key={personIdx}
                                                                        className={({active}) =>
                                                                            `${active ? 'bg-red-200' : ''} cursor-pointer select-none py-2 flex justify-center `
                                                                        }
                                                                        value={person}
                                                                    >
                                                                        {({}) => (
                                                                            <>
                                                        <span
                                                            className=""
                                                        >
                                                            <img alt="sprog" width={40} height={40} src={person.icon}/>
                                                        </span>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ))}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </Listbox>
                                                <div
                                                    className="text-xs md:text-xl appearance-none font-bold py-4 bg-transparent border-0 rounded text-gray-700 px-1 focus:outline-blue active:outline-none">
                                       <span className="ml-2 md:text-xl opacity-80">
                                          00{selected.countryCode}
                                       </span>
                                                </div>
                                                <input
                                                    value={phoneNumber}
                                                    onChange={event => setPhoneNumber(event.target.value)}
                                                    className="text-xs md:text-xl appearance-none font-bold py-4 bg-transparent border-0 md:w-full rounded text-gray-700 px-2 focus:outline-blue active:outline-none"
                                                    type="tel"
                                                    placeholder={t('Indtast mobilnummer (valgfri)')}
                                                    aria-label="phonenumber"
                                                    onKeyDown={validateOnKeyDownInput}
                                                    onPaste={validateOnPasteInput}
                                                />
                                            </div>
                                        </Listbox>


                                    </div>

                                    <div className="flex mb-12 justify-center items-center text-sm">
                                        <p>{t('Indtast mobilnummer og få en kvittering')}</p>
                                    </div>
                                    <div className="flex mb-6 mb-0 md:mt-10 mt-0 justify-center">
                                        <label className="inline-flex items-center">
                                            <input type="checkbox" checked={acceptTerms}
                                                   onClick={(ev) => setAcceptTerms(ev.target.checked)}
                                                   style={{color: "#07395c", outlineColor: "#07395c"}}
                                                   className="form-checkbox rounded p-3"/>
                                            <span className="ml-2 text-lg"> {t('Jeg accepterer')} <TermsModal
                                                text={t('vilkår og betingelser')} type={0}
                                                language={selected}/></span>
                                        </label>
                                    </div>
                                    <div
                                        className="flex mb-12 mt-6 justify-center items-center text-sm px-5 text-center">
                                        <p className="w-4/5 md:w-4/5 break-words">
                                            {t('GDPR')} <TermsModal
                                            text={t('GDPRLink')} type={1}
                                            language={selected}/>
                                        </p>
                                    </div>
                                    <div className="flex mb-6 justify-center items-center">
                                        <button disabled={!canContinue} style={{backgroundColor: "#07395c"}}
                                                className="l:w-2/5 md:w-2/5 w-2/5 bg-lightBlue-500 disabled:opacity-50 shadow-md text-white font-bold uppercase px-8 py-3 rounded hover:shadow-lg outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button" onClick={() => Continue()}>
                                            {t('Fortsæt')}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </>

            )}
        </>
    )
}
